.leaflet-search-wrap {
  z-index: 900;
}
.leaflet-search-control{
  width: auto;
  height: auto;
  text-align: center;
  font: bold 12px/20px Tahoma, Verdana, sans-serif;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  cursor: default;
  border-radius: 4px;
  display: flex;
  z-index: 802;
}

.leaflet-search-control-a {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA0klEQVR4AWOgCTA2Ng4E4k4gngTEhUAsRaxGQyB+DcT/seBaQppNkRRfA+IiIE4G4pVI4pNxaWYE4ptQRdOwyNshGeKCzYBgqOQFPC4shqpZj01yOlSyCo8BYlA1b7FJzoNKZuExgA+q5is2yQyo5EY8BvhD1RzDJqmIFEheOAy4AZVPwGVDBZIhlUAsAsTc0Bi4iSQniy8tNOFIRH+B+BOUfR+IBfEZognES4H4JSjAgPg4EHtD5Y5DDXlOKHnjs+Ac1JBecg2QBCVpIBamSs4FABbBfro4ePMqAAAAAElFTkSuQmCC');
  background-position: 50% 50%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-repeat: no-repeat;
  border-radius: 2px;
  border:0!important;
  height: 30px!important;
  width: 30px;
  line-height: 30px;
  z-index: 0;
  transition: height .1s linear 0s,
  border-top-right-radius 0s linear .1s,
  border-bottom-right-radius 0s linear .1s;
}
.leaflet-search-control-input {
  z-index: 50;
  outline: 0;
  padding: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  font-size: 14px;
  border: 0;
  height: 30px;
  box-sizing: border-box;
  width: 0;
  transition: width .1s linear 0s, height .1s linear 0s
}
.leaflet-search-control-close {
  display: none;
}
.leaflet-search-close {
  display: block;
}

.leaflet-search-control-active .leaflet-search-control-a {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  height: 40px!important;
  transition: height .1s linear 0s;
}

.leaflet-search-control-active .leaflet-search-control-input {
  padding:0px 6px;
  height: 40px;
  width: 240px;
}
.leaflet-search-control-active .leaflet-search-control-close {
  background-color: transparent;
  height: 40px;
  width: 26px;
  font: normal 18px/38px 'Lucida Console', Monaco, monospace;
  right: 0;
  color: gray;
  cursor: pointer;
  z-index: 51;
  position: absolute;
}

.leaflet-search-control-info {
  width: 270px;
  height: auto;
  position: absolute;
  background-color: white;
  top: 45px;
  box-sizing: border-box;
  padding: 0px;
  border: 2px solid rgba(166, 0, 0, 0.2);
  border-radius: 2px;
  text-align: center;
  overflow-y: auto;
}

.leaflet-search-control-info.close {
  display: none;
}

.leaflet-search-control-info-span {
  margin: 0 auto;
  font-weight: normal;
  font-size: 12px;
}

.leaflet-search-control-info-ul {
  margin: 0;
  padding: 0;
  overflow-y: auto;
}
.leaflet-search-control-info-li {
  border-bottom: 1px solid rgba(166, 0, 0, 0.2);
  font: normal 12px/20px Tahoma, Verdana, sans-serif;
  list-style: none;
  cursor: pointer;
  padding: 12px 10px;
}
.leaflet-search-control-info-li:last-child,
.leaflet-search-control-info-li:hover:last-child {
  border-bottom: none;
}

.leaflet-search-control-info-li:hover,
.leaflet-search-control-info-li.active {
  /* border-bottom: 1px solid rgba(0, 166, 53, 0.477); */
  /* font: normal 13px/20px Tahoma, Verdana, sans-serif; */
  transition: all .2s;
}
.leaflet-search-control-info-li.active,
.leaflet-search-control-info-li.active:hover {
  background-color: #00ae2f47;
}
.leaflet-search-control-info-li:hover {
  background-color: #f5f5f5;
}

.leaflet-search-control-info-li:hover p,
.leaflet-search-control-info-li.active p {
  margin: 0;
}

.leaflet-search-control-info-li p,
.leaflet-search-control-info-li p {
  margin: 0;
  /* padding: 13 0px; */
}

.leaflet-search-popup {
  width: 100%;
  height: 1px;
  background-color: #eee;
}
